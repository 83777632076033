<template>
  <div class="row p-3">
    <div class="col-xl-12">
      <div class="row border-bottom pt-3 pb-3">
        <div class="col-xl-6">
          <div class="form-group row align-items-center mb-1 mt-1">
            公告内容&nbsp;
            <div class="col pl-0">
              <input type="text" class="form-control form-control-sm" v-model="condition" v-on:blur="getNoticeList"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-12">
      <template v-for="row in noticeList">
        <div class="row align-items-center border-bottom" :key="row.recordId">
          <div class="col-xl-12">
            <div class="row">
              <div class="col-xl-4">
                <span>发布人:{{row.name}}</span>
              </div>
              <div class="col-xl-4 text-center">
                <span>发布时间:{{row.createdDate}}</span>
              </div>
              <div class="col-xl-4 text-right">
                <span>组织架构:{{row.path}}</span>
              </div>
            </div>
            <div class="row pt-3">
              <div class="col-xl-12">
                <div v-html="row.content"></div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props:{
    user: {
      type: Object
    }
  },
  mounted() {
    this.$emit('send', "1");
    this.getNoticeList();
  },
  data(){
    return{
      noticeList: [],
      condition: "",
    }
  },
  methods:{
    getNoticeList(){
      const notice ={};
      notice.groupId = this.user.departId;
      notice.departId = this.user.groupId;
      notice.condition = this.condition;
      this.$axios.fetchPost('kybOa/getNoticeList', notice).then((data) => {
        if (data && data.data) {
          this.noticeList = data.data;
        }
      }).catch(err => {console.log(err)})
    }
  }
}
</script>